import {FC} from "react";
import {PostItemProps} from "./PostListItem";
import {Card, CardActionArea, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

const PostGridItem : FC<PostItemProps> = ({post}) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <Link
                to={`/${post.postLink}`} // Adjust the URL to match your post structure
                style={{ textDecoration: 'none', color:'black' }} // Optional: removes underline from link
                onClick={() => window.scrollTo(0, 0)}
            >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={process.env.REACT_APP_APP_BASE_IMAGE_URL + post.postImage}
                    alt= {post.postTitle}
                />
                <CardContent>
                    <Typography gutterBottom variant="h2" component="div" sx={{
                        fontSize: 18,
                        lineHeight: 1.5, // Adjust line height as needed
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3, // Limits the text to 3 lines
                    }}>
                        {post.postTitle}
                    </Typography>
                </CardContent>
            </CardActionArea>

            </Link>
        </Card>
    );
};

export default PostGridItem;