import useFetchApi from "../../hooks/useFetchApi";
import {Post} from "../../models/Post";
import PostListItem from "../../components/post/PostListItem";
import {Container} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from 'react';
import HTDPagination from "../../components/HTDPagination";
import SearchBar from "../../components/SearchBar";
import {useSearch} from "../../context/SearchContext";
import ReactGA from "react-ga4";


const HomeScreen = () => {

    const { query, updateQuery } = useSearch()

    const [page, setPage] = React.useState(0);
    const offset = page > 0 ? (page - 1) * 9 : 0;

    ReactGA.send({
        hitType:"pageview",
        page:"/",
        title:"Home"
    })


    const handleChange = (value: number) => {
        setPage(value);
    };

    const url = (query?.length ?? 0) > 0
        ? `${process.env.REACT_APP_APP_BASE_API_URL}post/search?term=${query}&limit=9&offset=${offset}`
        : `${process.env.REACT_APP_APP_BASE_API_URL}post?limit=9&offset=${offset}`;


    const {data,totalCount } = useFetchApi<Post[]>(url)



    const handleSearch = (query: string) => {
        console.log("Search Query:", query);
        setPage(1);
        // Handle search logic here
        updateQuery(query)
    };
    return (
        <Container maxWidth="lg" sx={{background:"#FFFFFF"}}>

            <SearchBar onSearch={handleSearch} />
            {
                data?.map((post, index) => (
                    <React.Fragment key={post.id}>
                        <PostListItem post={post}/>
                        {index < data.length - 1 && <Divider sx={{my: 2}}/>}
                    </React.Fragment>
                ))
            }

            <HTDPagination  totalPage={totalCount} currentPage={page} onPageChange={handleChange} />

        </Container>
    );
};

export default HomeScreen;