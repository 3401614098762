import { createTheme } from '@mui/material/styles';
import {grey} from "@mui/material/colors";

// Create a theme instance with Poppins as the default font
const theme = createTheme({
    palette: {
        primary: {
            main: '#000000', // Black as primary color
            contrastText: '#ffffff', // White text for better contrast
        },
        secondary: {
            main: grey[500], // Using grey as a secondary accent color
        },
        background: {
            default: '#f5f5f5', // Light grey background
            paper: '#ffffff',   // White background for paper elements
        },
        text: {
            primary: '#000000', // Black text color
            secondary: grey[600], // Grey text for secondary information
        },
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: 'lg', // Default to 'lg' breakpoint width
            }
        },
    },
    typography: {

        h2: {
            fontWeight: 600,
            fontSize: '22px',
            textAlign: 'left',
        },
        fontFamily: 'Poppins, sans-serif',
    },

});

export default theme;
