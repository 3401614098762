import './App.css';
import * as React from 'react';
import AppBar from "./components/AppBar";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Privacy from "./pages/privacy/Privacy";
import CategoryScreen from "./pages/category/CategoryScreen";
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Grid from "@mui/material/Grid2";
import HomeScreen from "./pages/home/HomeScreen";
import Sidebar from "./pages/home/components/Sidebar";
import {SearchProvider} from "./context/SearchContext";
import PostDetails from "./pages/detail/PostDetails";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-SSK9K0HTL8");
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="App">
                <SearchProvider>
                    <Router>
                        <AppBar/>
                        <Grid container spacing={2} padding="12px" columns={{xs: 4, sm: 8, md: 12}} sx={{background:"#FFFFFF"}} maxWidth="1300px"
                              justifySelf="center" width='100%'>
                            <Grid size={{xs: 4, sm: 8, md: 8}}>
                                <Routes>
                                    <Route path="/" element={<HomeScreen/>}/>
                                    <Route path="/category/:categoryName" element={<CategoryScreen/>}/>
                                    <Route path="/privacy-policy" element={<Privacy/>}/>
                                    <Route path="/:postLink" element={<PostDetails/>}/>
                                </Routes>
                            </Grid>

                            <Grid size={{xs: 4, sm: 8, md: 4}} width='100%'>
                                <Sidebar/>
                            </Grid>
                        </Grid>
                    </Router>
                </SearchProvider>
            </div>
        </ThemeProvider>
    );
}

export default App;
