import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {Box, useMediaQuery } from "@mui/material";
import theme from "../theme";

interface SearchBarProps {
    onSearch?: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    const [query, setQuery] = useState<string>('');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        if (onSearch) {
            onSearch(value);
        }
    };

    return (
        <Box sx={{ background: "#f5f5f5" }}>
        <TextField
            label="Search"
            variant="outlined"
            value={query}
            onChange={handleSearch}
            sx={{
                my: 3,
                width: isMobile ? '100%' : '50%',
                mx: 'auto',
                borderRadius: 2,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s ease',
                '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: 'white',
                    '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                    },
                },
                '& .MuiInputAdornment-root': {
                    color: theme.palette.grey[500],
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
        </Box>
    );
};

export default SearchBar;
