import useFetchApi from "../../../hooks/useFetchApi";
import {Post} from "../../../models/Post";
import {Avatar, ListItemAvatar, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ReactGA from "react-ga4";


const TopPicks =() => {

    const { data } = useFetchApi<Post[]>(`${process.env.REACT_APP_APP_BASE_API_URL}post/topPicks`);

    return (
        <Stack direction="column">
            <Typography variant="h2" sx={{my:2}}>Top Picks</Typography>
            <List sx={{background: '#ffffff'}}>
                {data?.map((post, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton
                            component={Link}
                            to={`/${post.postLink}`}
                            sx={{textDecoration: 'none', color: 'inherit'}}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Navigation',
                                    action: 'Clicked on TopPicks',
                                    label: post.postTitle,
                                });
                            }} // Track click event
                        >
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt={post.postTitle}  src={process.env["REACT_APP_APP_BASE_IMAGE_URL"] + post.postImage} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={post.postTitle}
                                />
                            </ListItem>
                        </ListItemButton>
                        {index < data.length - 1 && <Divider sx={{backgroundColor: '#dfdfdf'}}/>}
                    </React.Fragment>
                ))}
            </List>
        </Stack>
    );

};

export default TopPicks;