import { useEffect, useState } from "react";

const useFetchApi = <T>(url: string) => {
    const [data, setData] = useState<T | null>(null);
    const [totalCount, setTotalCount] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }

                // Parse the response data
                const result: T = await response.json();
                setData(result);

                // Get x-total-count from headers
                const totalCountHeader = response.headers.get("x-total-count");
                if (totalCountHeader) {
                    setTotalCount(Number(totalCountHeader));
                }
            } catch (error) {
                // Handle error
            } finally {
                // Handle loading state if necessary
            }
        };

        fetchData();
    }, [url]);

    return { data, totalCount };
}

export default useFetchApi;
