// AuthContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface SearchContextType {
    query: string | null;
    updateQuery: (query: string) => void;
}

export const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [query, setQuery] = useState<string | null>(null);

    const updateQuery = (query: string) => {
        setQuery(query); // Set user as logged in
    };

    return (
        <SearchContext.Provider value={{ query, updateQuery }}>
            {children}
        </SearchContext.Provider>
    );
};

// Custom hook for using auth context
export const useSearch = (): SearchContextType => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
