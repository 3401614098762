import React from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
const buildAbsoluteImageURL = (url) => {
    // Function to build the absolute URL
    if (!url.startsWith("http")) {
        return `${process.env.REACT_APP_APP_BASE_IMAGE_URL}${url}`; // Replace with your base URL
    }
    return url;
};

const buildAbsoluteURL = (url) => {
    // Function to build the absolute URL
    if (!url.startsWith("http")) {
        return `${process.env.REACT_APP_APP_BASE_URL}/${url}`; // Replace with your base URL
    }
    return url;
};

const MarkdownRenderer = ({ content }) => {
    return (
        <ReactMarkdown
            className="markdown-content"
            style={{ fontFamily: 'Poppins, sans-serif' }}
            components={{
                a: ({ node, ...props }) => (
                    <a
                        {...props}
                        href={buildAbsoluteURL(props.href)}
                        target="_blank"
                    >
                        {props.children}
                    </a>
                ),
                img: ({ node, ...props }) => (
                    <img
                        {...props}
                        src={buildAbsoluteImageURL(props.src)}
                        alt={props.alt || "Markdown Image"}
                        style={{
                            display: "block",
                            margin: "20px auto", // centers the image horizontally
                            maxWidth: "70%",
                            height: "auto",
                        }}
                    />
                ),
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <SyntaxHighlighter language={match[1]} PreTag="div" {...props}>
                            {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                    ) : (
                        <code {...props} className={className}>
                            {children}
                        </code>
                    );
                },
            }}
        >
            {content}
        </ReactMarkdown>
    );
};

export default MarkdownRenderer;
