import {Stack} from "@mui/material";
import CategoryList from "./CategoryList";
import TopPicks from "./TopPicks";
import React from "react";
import ProfileCard from "../../../components/common/ProfileCard";



const Sidebar = () => {

    return (
        <Stack direction="column" spacing={2} sx={{background: '#dfdfdf', p: 2}}>
            <ProfileCard />
            <CategoryList />
            <TopPicks />
        </Stack>

    );
};

export default Sidebar;