import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import useFetchApi from "../../../hooks/useFetchApi";
import {Category} from "../../../models/Category";
import {useStringUtils} from "../../../hooks/useStringUtils";
import ReactGA from "react-ga4";



const CategoryList = () => {
    const {data} = useFetchApi<Category[]>(`${process.env.REACT_APP_APP_BASE_API_URL}category`)
    const { replaceSpaceWithHyphen  } = useStringUtils()

    return (
        <>
            <Typography variant="h2" textAlign='left'>Category</Typography>

            <List sx={{background: '#ffffff'}}>
                {data?.map((category, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton
                            component={Link}
                            to={`/category/${replaceSpaceWithHyphen(category.categoryName)}`}
                            sx={{textDecoration: 'none', color: 'inherit'}}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Navigation',
                                    action: 'Clicked on Category',
                                    label: category.categoryName,
                                });
                            }} // Track click event
                        >
                            <ListItemText
                                primary={category.categoryName}
                                secondary={null}
                            />
                        </ListItemButton>
                        {index < data.length - 1 && <Divider sx={{backgroundColor: '#dfdfdf'}}/>}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};

export default CategoryList;