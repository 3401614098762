import {Avatar, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {BrightnessMedium, Fingerprint, GitHub, LinkedIn} from "@mui/icons-material";
import {blur} from "@testing-library/user-event/dist/blur";
import {grey} from "@mui/material/colors";
import ReactGA from "react-ga4";


const ProfileCard = () => {
        // Reusable styles for icon buttons
        const iconButtonStyles = (bgColor) => ({
            backgroundColor: bgColor,
            '&:hover': {
                backgroundColor: bgColor,
            },
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
        });

        return (
        <Stack spacing={2} direction="column" sx={{p:2,background: '#ffffff',justifyContent: 'center',}}>
            <Avatar alt="Remy Sharp" src="https://www.howtodoandroid.com/images/profile.webp" sx={{ width: 200, height: 200, alignSelf:"center" }} />

            <Typography variant="h2" sx={{textAlign:"center", fontSize: 18}}>Velmurugan Murugesan</Typography>
            <Typography variant="h6"  sx={{textAlign:"center" , fontSize: 16}}>Lead Engineer - Android</Typography>

            <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                {/* GitHub Button */}
                <IconButton
                    aria-label="GitHub"
                    component="a"
                    href="https://github.com/velmurugan-murugesan"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={iconButtonStyles('black')}
                    onClick={() => {
                        ReactGA.event({
                            category: 'GitHub',
                            action: 'Clicked on GitHub Icon',
                            label: 'GitHub Profile',
                        });
                    }}
                >
                    <GitHub />
                </IconButton>

                {/* LinkedIn Button */}
                <IconButton
                    aria-label="LinkedIn"
                    component="a"
                    href="https://www.linkedin.com/in/velmurugan-m/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={iconButtonStyles('blue')}
                    onClick={() => {
                        ReactGA.event({
                            category: 'LinkedIn',
                            action: 'Clicked on LinkedIn Icon',
                            label: 'LinkedIn Profile',
                        });
                    }}
                >
                    <LinkedIn />
                </IconButton>
            </Stack>

        </Stack>
    );
}

export default ProfileCard;