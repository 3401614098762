import {useCallback} from "react";

export const useStringUtils = () => {

    const replaceSpaceWithHyphen = useCallback((input: string) => {
        return input.replace(/ /g, '-');
    }, [])


    const replaceHyphenWithSpace = useCallback((input: string): string => {
        return input.replace(/-/g, ' ');
    }, []);

    return { replaceSpaceWithHyphen, replaceHyphenWithSpace };
};