import {Pagination} from "@mui/material";
import React, {FC} from "react";
import Box from "@mui/material/Box";

interface HTDPaginationProps {
    totalPage: number | null;
    currentPage: number;
    onPageChange: (page: number) => void;
}


const HTDPagination: FC<HTDPaginationProps> = (props: HTDPaginationProps) => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.onPageChange(value);
    };
    return (
            <Pagination
                sx={{my: 2, justifySelf: 'center', placeItems:'center', width:'100%'}}
                count={props.totalPage ? Math.ceil(props.totalPage / 9) : 0} page={props.currentPage}
                onChange={handleChange}/>

    );
}
export default HTDPagination;