import {FC} from "react";
import useFetchApi from "../hooks/useFetchApi";
import {Post} from "../models/Post";
import Grid from "@mui/material/Grid2";
import PostGridItem from "./post/PostGridItem";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

interface RelatedPostsProps {
    category: string;
}


const RelatedPosts : FC<RelatedPostsProps> = ({category}) => {


    const { data } = useFetchApi<Post[]>(`${process.env.REACT_APP_APP_BASE_API_URL}post/search?term=${category}&limit=6&offset=0`);

    return (
        <Box sx={{p:2, background:"#dfdfdf"}}>
            <Stack direction="column" >
                <Typography variant="h3" sx={{py:2 , fontSize:22}}>Related Posts</Typography>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {data?.map((post, index) => (
                        <Grid key={index} size={{ xs: 4, sm: 4, md: 4 }}>
                            <PostGridItem  post={post} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>



        </Box>
    );
};

export default RelatedPosts;