import {Post} from "../../models/Post";
import React from "react";
import Grid from "@mui/material/Grid2";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export interface PostItemProps {
    post: Post
}

const PostListItem: React.FC<PostItemProps> = ({post}) => {


    return (
        <Link
            to={`/${post.postLink}`} // Adjust the URL to match your post structure
            style={{ textDecoration: 'none', color:'black' }} // Optional: removes underline from link
        >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }} padding="12px">
                <Grid  size={{ xs: 4, sm: 8, md: 8 }}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="h2">{post.postTitle}</Typography>
                        <Typography variant="body1" sx={{textAlign:'left'}}>{post.postDesc}</Typography>
                    </Stack>
                </Grid>

            <Grid  size={{ xs: 4, sm: 4, md: 4 }}>
                <img src={process.env.REACT_APP_APP_BASE_IMAGE_URL + post.postImage}  style={{width: "100%", height: "170px"}} alt={post.postTitle} />
            </Grid>

        </Grid>

        </Link>
    );
};

export default PostListItem;